<template>
  <div class="homepage">
    <div class="rf-sign-up">
      <b-container>
        <b-row class="mt-5">
          <b-col cols="12" md="6" class="position-relative mt-5 " v-if="registered && user.authType === 'MAIL'">
            <h1 class="accomodation-title">{{$t('Confirm your email address')}}</h1>
            <div class="accomodation-text  pt-2 mt-0 pb-2">
              <p class="text-line">{{$t('We send you a confirmation email to verify your address. Please follow the instrunctions to enable your account.')}}</p>
            </div>
          </b-col>
          <b-col cols="12" md="6" class="position-relative mt-5 " v-if="registered && user.authType !== 'MAIL'">
            <h1 class="accomodation-title">{{$t('Your account has been successfully created!')}}</h1>
          </b-col>
          <b-col cols="12" md="6" class="position-relative mt-5 " v-if="!registered">
            <h1 class="accomodation-title">{{$t('Create your account')}}</h1>
            <div class="accomodation-text  pt-2 mt-0 pb-2">
              <p class="text-line">{{$t('You can either create your account using your gmail account, or you facebook account, or you email address.')}}</p>
            </div>
            <b-row class="p-2">
              <div v-if="clientId" id="google-login-btn" class="google-login-btn"
                v-google-identity-login-btn="{ clientId, locale:'en' }">
                Google
              </div>
            </b-row>
            <b-row class="p-2">
              <button class="facebook-login-btn" @click="$refs.facebookButton.login()">
                <img class="facebook-login-img" src="@/assets/images/facebook_letter.png" />
                Log In To Facebook
              </button>
              <facebook-login v-if="fbAppId" ref="facebookButton" class="facebook-login" :appId="fbAppId" @login="onLogin"
                @sdk-loaded="sdkLoaded">
              </facebook-login>

            </b-row>
            <!-- <b-row class="p-2">
              <RFButton
                transparent
                border-color="transparent"
                :icon="['fa', 'envelope']"
                :iconSize="'lg'"
                color="#fff"
                label="Signup with falso Google"
                @on-click="fakeGoogle()"/>
            </b-row> -->
            <!-- <b-row class="p-2">
              <RFButton
                transparent
                border-color="transparent"
                :icon="['fab', 'facebook-f']"
                :iconSize="'lg'"
                color="#fff"
                :label="$t('Signup with ') + 'Facebook'"
                @on-click="loginWithFacebook()"/>
            </b-row> -->
            <div class="accomodation-text  pt-2 mt-0 pb-2">
              <p class="text-line">{{$t('By signing up you agree to the')}}&nbsp;<router-link
                  :to="{name: 'privacy-policy-page'}">{{$t('Terms of Service.')}}</router-link>
              </p>
            </div>
          </b-col>
          <b-col cols="12" md="6" class="mt-5 mt-md-0 px-0 pr-md-3">
            <div>
              <ValidationObserver ref="validator">
                <form ref="validator" @submit.prevent="saveUser">
                  <div class="info">
                    <RFValidation :name="$t('Firstname')" rules="required">
                      <RFInput :label="$t('Firstname')" v-model="user.firstname" :value="user.firstname"
                        :readonly="loading" />
                    </RFValidation>
                    <RFValidation :name="$t('Lastname')" rules="required">
                      <RFInput :label="$t('Lastname')" v-model="user.lastname" :value="user.lastname"
                        :readonly="loading" />
                    </RFValidation>
                    <RFValidation :name="$t('Email')" rules="required|email">
                      <RFInput :label="$t('Email')" v-model="user.email" :value="user.email" :readonly="loading" />
                    </RFValidation>
                    <RFValidation :name="$t('Confirm email')" rules="required">
                      <RFInput :label="$t('Confirm email')" v-model="user.confirmEmail" :value="user.confirmEmail"
                        :readonly="loading" />
                    </RFValidation>
                    <RFValidation rules="required|minLength|password" name="Password">
                      <RFInput label="Password" type="password" v-model="user.password" :readonly="loading" />
                    </RFValidation>
                    <RFValidation rules="required" name="Confirm Password">
                      <RFInput label="Confirm Password" type="password" v-model="user.confirmPassword"
                        :readonly="loading" />
                    </RFValidation>
                    <b-row align-h="end" class="m-2">
                      <RFButton transparent border-color="transparent" :icon="['fa', 'envelope']" :iconSize="'lg'"
                        color="#fff" :label="$t('Signup using your email')" @on-click="useEmail = true" v-if="!loading"/>
                      <RFLoader :size="30" color="#24425B" v-if="loading" />
                    </b-row>
                  </div>
                </form>
              </ValidationObserver>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="rf-type">
      <b-container fluid>
        <b-row>
          <b-col cols="12" md="6" class="rf-sign-up apartments mb-5 mb-md-0 px-0">
            <div class="rf-apartments">
              <h1 class="title">{{$t('Apartments')}}</h1>
              <router-link :to="{ name: 'apartments-page' }">
                <RFButton class="px-5" :label="$t('Discover')" background="#67AEA6" color="#fff"
                  border-color="transparent" />
              </router-link>
            </div>
          </b-col>
          <b-col cols="12" md="6" class="rf-sign-up buildings px-0">
            <div class="rf-buildings">
              <h1 class="title">{{$t('RooF Buildings')}}</h1>
              <router-link to="/buildings">
                <RFButton class="px-5" :label="$t('Discover')" background="#E26F5A" color="#fff"
                  border-color="transparent" />
              </router-link>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import RFInput from '@/components/forms/RFInput'
import RFButton from '@/components/forms/RFButton'
import RFValidation from '@/components/forms/RFValidation'
import RFLoader from '@/components/forms/RFLoader'
import { userService } from '../../services'

import GoogleSignInButton from 'vue-google-identity-login-btn'
import facebookLogin from 'facebook-login-vuejs';

export default {
  components: {
    RFInput,
    RFButton,
    RFValidation,
    RFLoader,
    facebookLogin
  },
  directives: {
    GoogleSignInButton
  },
  data() {
    return {
      clientId : null,
      fbAppId: null,
      registered: false,
      useEmail: false,
      isConnected: false,
      name: '',
      email: '',
      personalID: '',
      FB: undefined,
      user: {
        firstname: '',
        lastname: '',
        email: '',
        confirmEmail: '',
        oauthId: '',
        password: '',
        confirmPassword: '',
        authType: ''
      },
      loading: false,
    }
  },
  beforeMount() {
    this.clientId = process.env.VUE_APP_GOOGLE_CLIENT_ID
    this.fbAppId = process.env.VUE_APP_FB_APP_ID
  },
  methods: {
    // async fakeGoogle () {
    //   this.loading = true
    //   this.user.firstname = 'Fulanito'
    //   this.user.lastname = 'Fulanosky'
    //   this.user.email = 's111@fulanosky.com'
    //   this.user.password = '3211534545454661'
    //   this.user.oauthId = '3211534545454661'
    //   try {
    //     this.user.authType = 'GOOGLE'
    //     const result = await userService.createUser(this.user)
    //     if (result.status === 202) {
    //       console.info(result.headers.state)
    //       this.$toasted.info(result.headers.state)
    //     } else if (result.status === 201) {
    //       console.log(result.headers.state)
    //       this.$toasted.success(result.headers.state)
    //       this.registered = true
    //     } else if (result.status === 204) {
    //       console.error(result.headers.state)
    //       this.$toasted.error(result.headers.state)
    //     }
    //   } catch {
    //     console.error(err.message)
    //     this.$toasted.error(err.message)
    //   }
    // },
    async onGoogleAuthSuccess (jwtCredentials) {
      this.loading = true
      const profileData = JSON.parse( atob(jwtCredentials.split('.')[1]) );
      this.user.firstname = profileData.given_name
      this.user.lastname = profileData.family_name
      this.user.email = profileData.email
      this.user.password = profileData.sub
      this.user.oauthId = profileData.sub
      this.user.authType = 'GOOGLE'
      try {
        const result = await userService.createUser(this.user)
        if (result.status === 202) {
          this.$toasted.info(result.headers.state)
        } else if (result.status === 201) {
          this.$toasted.success(result.headers.state)
          this.registered = true
        } else if (result.status === 204) {
          this.$toasted.error(result.headers.state)
        }
      } catch {
        this.$toasted.error(err.message)
      }
      this.loading = false
    },
    async getUserData() {
      this.loading = true
      this.$store.state.userStore.Facebook.api('/me', 'GET', { fields: 'id,name,email' },
        userInformation => {
          this.user.firstname = userInformation.name;
          this.user.lastname = ''
          this.user.email = userInformation.email
          this.user.password = userInformation.id
          this.user.oauthId = userInformation.id
          this.user.authType = 'FACEBOOK'
          userService.createUser(this.user).then(result => {
            if (result.status === 202) {
              this.$toasted.info(result.headers.state)
            } else if (result.status === 201) {
              this.$toasted.success(result.headers.state)
              this.registered = true
            } else if (result.status === 204) {
              this.$toasted.error(result.headers.state)
            }
          })
        }
      )
      this.loading = false
    },
    sdkLoaded(payload) {
      // this.isConnected = payload.isConnected
      this.$store.commit('userStore/setFacebook', payload)
      // if (this.isConnected) this.getUserData()
    },
    onLogin() {
      this.isConnected = true
      this.getUserData()
    },
    onLogout(){
      this.isConnected = false
    },
    resetFields () {
      this.user.firstname = ''
      this.user.lastname = ''
      this.user.email = ''
      this.user.confirmEmail = ''
      this.user.password = ''
      this.user.oauthId = ''
      this.user.confirmPassword = ''
      this.user.authType = ''
    },
    async saveUser() {
      try {
        this.loading = true
        const isValid = await this.$refs.validator.validate()
        if (isValid) {
          if (this.user.password !== this.user.confirmPassword) {
            this.$toasted.error(this.$i18n.t(`Password and Confirm Password doesn't match`))
            this.loading = false
            return
          }
          if (this.user.email !== this.user.confirmEmail) {
            this.$toasted.error(this.$i18n.t(`Email and Confirm Email doesn't match`))
            this.loading = false
            return
          }
          this.user.authType = 'MAIL'
          const result = await userService.createUser(this.user)
          if (result.status === 202) {
            this.$toasted.info(result.headers.state)
          } else if (result.status === 201) {
            this.$toasted.success(result.headers.state)
            this.registered = true
          } else if (result.status === 204) {
            this.$toasted.error(result.headers.state)
          }
        }
      } catch (err){
        this.$toasted.error(err.message)
      }
      this.$refs.validator.reset()
      this.resetFields()
      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>

.facebook-login {
  display: none;
}

.facebook-login-btn {
  color: #FFF;
  font-size: 16px;
  min-width: 300px;
  background-image: linear-gradient(#4C69BA, #3B55A0);
  border: none;
  border-radius: 3px;
  line-height: 40px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.facebook-login-img {
  height: 30px;
  width: 30px;
  margin-right: 15px;
}

.google-login-btn {
  margin-left: 10px;
}

.homepage {
  .rf-sign-up {
    margin-top: 10vh;
    position: relative;

    .accomodation-title {
      @include font-style($montserrat-bold, 'bold', $font-26);
      color: $dark-blue;
    }
    .accomodation-text {
      margin-top: 40px;

      .text-line {
        @include font-style($montserrat, 'medium', $font-16);
        color: $dark-blue;
        margin: 0;
      }
    }
    .accommodation-texture {
      width: 100vw;
      background-image: url('/images/gray_texture.png');
      position: absolute;
      height: 200px;
      z-index: 5;

      &.first {
        left: -100vw;
      }
    }
    .booking-form-container {
      background-color: $dark-blue;
      padding: 15px;
      position: relative;
      z-index: 5;

      &:after,
      &:before {
        content: '';
        width: 100vw;
        height: 100%;
        background-color: $dark-blue;
        position: absolute;
        top: 0;
        z-index: -1;
      }
      &:before {
        right: 50%;
      }
      &:after {
        left: 50%;
      }

      .booking-label {
        @include font-style($montserrat-bold, 'bold', $font-14);
        color: $white;
        margin-top: 20px;
        white-space: nowrap;
      }
    }
  }
  .rf-explore {
    background: $gray;
    padding: 30px 0;

    .explore-text {
      @include font-style($montserrat-bold, 'bold', $font-20);
      text-align: center;
      margin: 0;
      color: $dark-blue;
    }
  }
  .rf-type {
    position: relative;

    .rf-sign-up {
      height: 50vh;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &.apartments {
        background-image: url('/images/A-image.jpg');
        border-bottom: 10px solid $green;

        .rf-apartments {
          text-align: center;
        }
      }
      &.buildings {
        background-image: url('/images/R-image.jpg');
        border-bottom: 10px solid $orange;

        .rf-buildings {
          text-align: center;
        }
      }
    }
    .title {
      @include font-style($montserrat-bold, 'bold', $font-24);
      color: $white;
    }
  }
  .rf-services {
    padding: 100px 0;

    .title {
      @include font-style($montserrat-bold, 'bold', $font-26);
      color: $dark-blue;
      margin-bottom: 50px;
    }
    .text {
      @include font-style($montserrat, 'medium', $font-16);
      color: $dark-blue;
    }
    .service-image {
      width: 60%;
    }
  }
  .rf-roof-world {
    background-image: url('/images/home_roof_world.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 70vh;
    display: flex;
    align-items: center;

    .title {
      @include font-style($montserrat-bold, 'bold', $font-26);
      color: $dark-blue;
    }
    .text {
      @include font-style($montserrat, 'medium', $font-16);
      color: $dark-blue;
      margin-top: 100px;
    }
  }
}

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  .homepage {
    .rf-sign-up {
      .accomodation-text {
        width: 80%;
      }
      .booking-form-container {
        padding: 50px 0 50px 50px;
        position: static;
        z-index: 0;

        &:before {
          display: none;
        }
        &:after {
          display: block;
        }
        .booking-form {
          position: relative;
          z-index: 10;
        }
      }
    }
    .rf-type {
      .rf-sign-up {
        height: 60vh;

        &.buildings,
        &.apartments {
          // align-items: flex-end;

          .rf-buildings,
          .rf-apartments {
            padding: 0px;
            text-align: center;
          }
        }
      }
    }
    .rf-services {
      .service-image {
        width: 40%;
      }
    }
  }
}

@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
  .homepage {
    .rf-services {
      .service-image {
        width: 80px;
      }
    }
  }
}
</style>
